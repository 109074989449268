import React from "react"
import { Link, graphql } from "gatsby"
import { dateLarge } from "../../utils/Utils"
import Page from "../../components/page/Page"
import NavList from "../main/NavList"

export default props => {
  const opinionEdges = props.data.allContentfulOpinion.edges
  return (
    <Page class="opinion" slug="opinion" title="Opinión">
      <div className="list">
        <header className="list-header">
          <h1 className="title">Opinión</h1>
          <div className="subtitle">
            Mi lectura de la realidad, publicada en medios peruanos e
            internacionales
          </div>
        </header>
        <main className="list-main">
          {opinionEdges.map((edge, i) => (
            <Link key={i} className="item" to={`/opinion/${edge.node.slug}`}>
              <h2 className="item-title">{edge.node.titulo}</h2>
              <div className="item-date">{dateLarge(edge.node.fecha)}</div>
              <div className="item-excerpt">
                {edge.node.texto.childMarkdownRemark.excerpt}
              </div>
            </Link>
          ))}
        </main>
        <footer className="list-footer">
          <NavList
            route="/opinion/"
            currentPage={props.pageContext.currentPage}
            totalPages={props.pageContext.totalPages}
          />
        </footer>
      </div>
    </Page>
  )
}

export const opinionListQuery = graphql`
  query opinionListQuery($skip: Int, $limit: Int) {
    allContentfulOpinion(
      sort: { fields: fecha, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          slug
          titulo
          fecha
          texto {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  }
`
