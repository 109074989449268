import React from "react"
import { Link } from "gatsby"
import "./Navs.css"

export default props => {
  const route = props.route
  const currentPage = props.currentPage
  const totalPages = props.totalPages
  return (
    <nav className="items-nav">
      {currentPage > 1 && (
        <Link
          className="items-nav--left"
          to={
            currentPage === 2
              ? `${route}`
              : `${route}${(currentPage - 1).toString()}`
          }
        >
          <i className="material-icons">navigate_before</i>
          <span>anterior</span>
        </Link>
      )}
      {currentPage !== totalPages && (
        <Link
          className="items-nav--right"
          to={`${route}${(currentPage + 1).toString()}`}
        >
          <span>siguiente</span>
          <i className="material-icons">navigate_next</i>
        </Link>
      )}
    </nav>
  )
}
